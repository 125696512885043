/* eslint-disable @typescript-eslint/no-explicit-any */
import { parseSearchState, SearchProvider } from '@faststore/sdk'
import { graphql } from 'gatsby'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import { useEffect, useState } from 'react'
import ProductGalleryV2 from 'src/components/sections/ProductGalleryV2/ProductGallery'
import { ITEMS_PER_PAGE } from 'src/constants'
import { applySearchState } from 'src/sdk/search/state'
import { Helmet } from 'react-helmet-async'
import { mark } from 'src/sdk/tests/mark'
import type { SearchState } from '@faststore/sdk'
import type { PageProps } from 'gatsby'
import type {
  SearchPageQueryQuery,
  SearchPageQueryQueryVariables,
} from '@generated/graphql'
import { getCMSPageByContentType } from 'src/utils/getCMSPageDataByContentType'
import MainBannerBrands from 'src/components/brands/mainBanner/mainBanner'
import SectionBrand from 'src/components/brands/sectionBrand/sectionBrand'

import './styles.scss'

type ServerData = {
  contentBrand: any
}

export type Props = PageProps<
  SearchPageQueryQuery,
  SearchPageQueryQueryVariables,
  ServerData
>

interface PropsCMS extends Props {
  slug: string
  serverData: {
    contentBrand?: ContentBrand
  }
}

interface ContentBrand {
  status: any
  props: CmsData
  headers: any
}
interface CmsData {
  cmsData: Sections
}

interface Sections {
  sections: Array<{
    data: {
      items: {
        sectionA: {
          brandName: string
          brandType: string
          brandSEOTitle: string
          brandSEODescription: string
          brandSEOKeywords: string
          brandLogo: string
        }
        // eslint-disable-next-line
        sectionB: any
        // eslint-disable-next-line
        sectionC: any
      }
    }
    id: string
    name: string
  }>
  status: string
}

const useSearchParams = ({ href }: Location, brand: any) => {
  const [params, setParams] = useState<SearchState | null>(null)

  useEffect(() => {
    const url = new URL(href)

    const searchState = parseSearchState(url)

    const facetCondition = searchState.selectedFacets.find(
      (facet) => facet.key === 'marca' && facet.value === brand
    )

    if (facetCondition) {
      setParams(searchState)
    } else {
      searchState.selectedFacets.push({
        key: 'marca',
        value: brand,
      })
      setParams(searchState)
    }
  }, [href, brand])

  return params
}

function Page(props: PropsCMS) {
  const { serverData } = props

  const cmsData = serverData?.contentBrand?.props?.cmsData
  const canonical = props.location.pathname

  const brand = cmsData?.sections[0]?.data?.items?.sectionA?.brandName
  const searchParams = useSearchParams(props.location, brand)
  const substituteTitle = `${brand} | Decathlon`
  const title =
    cmsData?.sections[0]?.data?.items?.sectionA?.brandSEOTitle ||
    substituteTitle

  const substituteDescription = `${brand}, a Decathlon online é a maior loja de esportes com produtos fitness, de musculação, ciclismo, futebol, corrida, camping, trilha, natação e mais. Confira!`
  const description =
    cmsData?.sections[0]?.data?.items?.sectionA?.brandSEODescription ||
    substituteDescription

  const keywords = cmsData?.sections[0]?.data?.items?.sectionA?.brandSEOKeywords

  if (!searchParams) {
    return null
  }

  if (serverData === null) {
    return null
  }

  return (
    <SearchProvider
      onChange={applySearchState}
      itemsPerPage={ITEMS_PER_PAGE}
      {...searchParams}
    >
      {/* SEO */}
      <Helmet>
        <meta name="keywords" content={keywords} />
        <meta
          property="og:image"
          content={cmsData?.sections[0].data.items.sectionA.brandLogo}
        />
      </Helmet>
      <GatsbySeo
        language="pt-br"
        title={title}
        description={description}
        canonical={`https://www.decathlon.com.br${canonical}`}
      />

      {/* MAIN BANNER MARCA  */}
      <MainBannerBrands data={cmsData?.sections[0]?.data?.items?.sectionA} />

      {/* BREADCRUMB */}
      <div className="breadcrumb__brand layout__content mt-5 mb-11">
        <a href="/">Página Inicial</a>
        <a href="/marcas">Marcas</a>
        <a
          href={`/marcas/${searchParams.selectedFacets[0].value.toLocaleLowerCase()}`}
        >
          {searchParams.selectedFacets[0].value}
        </a>
      </div>

      {/* SECTION BRAND */}
      <SectionBrand data={cmsData} />

      {/* VITRINE */}
      <ProductGalleryV2
        dataBrand={cmsData!}
        title="Marcas"
        searchEngine="vtex"
        type="search"
        typeValidation="byFilter"
      />
    </SearchProvider>
  )
}

export const querySSG = graphql`
  query ProductPageBrandSite {
    site {
      siteMetadata {
        titleTemplate
        title
        description
      }
    }
  }
`
export const getServerData = async ({
  params: { slug },
}: {
  query: Record<string, string>
  params: Record<string, string>
}) => {
  const CACHE_CONTROL = `max-age=300, s-maxage=7200, stale-while-revalidate`

  const contentBrand = await getCMSPageByContentType({
    slug,
    contentType: 'marcas',
  })

  if (contentBrand.status === 301) {
    return {
      status: 301,
      props: {},
      headers: {
        'cache-control': CACHE_CONTROL,
        location: '/marcas',
      },
    }
  }

  return {
    status: 200,
    props: {
      contentBrand,
      slug,
    },
    headers: {
      'cache-control': CACHE_CONTROL,
    },
  }
}

Page.displayName = 'Page'
export default mark(Page)
